@import 'src/styles/shared';

.html-title-display {
  z-index: 999;
  @include font-display();
  line-height: px-to-rem(50px);
  text-align: left;
  position: absolute;

  &.mobile {
    padding-left: px-to-rem(26px);
    padding-right: px-to-rem(26px);
  }

  &.first {
    color: $red;
    pointer-events: none;

    .first-line {
      font-weight: 800; // bold
      font-size: 1em;
    }

    .second-line {
      font-size: px-to-rem(49px);
      font-weight: 600; // bold
    }

    &.desktop, &.tablet {
      top: px-to-rem(298px);
      left: px-to-rem(160px);
      font-size: px-to-rem(60px);
      //width: px-to-rem(284px);
    }

    &.mobile {
      font-size: px-to-rem(35px);
      top: px-to-rem(200px);
      left: 0;
      width: 100%;
      //text-align: center;
    }
  }

  &.second {
    color: $yellow;
    pointer-events: none;

    margin-top: px-to-rem(10px);
    font-weight: 800;
    text-transform: uppercase;

    &.desktop, &.tablet {
      top: px-to-rem(445px);
      left: px-to-rem(160px);
      width: px-to-rem(176px);
      font-size: px-to-rem(80px);
    }

    &.mobile {
      top: px-to-rem(300px);
      left: 0;
      width: 100%;
      text-align: center;
      padding-top: 20px;
      font-size: px-to-rem(25px);
    }

    .deploy-btn {
      background: $red;
      color: white;
      pointer-events: auto;
      cursor: pointer;
      font-size: px-to-rem(30px) !important;
      padding: px-to-rem(15px) px-to-rem(30px);
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background: black;
      }

      &.desktop, &.tablet {
        font-size: px-to-rem(80px);
      }

      &.mobile {
        font-size: px-to-rem(40px);
      }
    }
  }

  &.third {
    color: $beige;
    pointer-events: none;

    font-size: px-to-rem(49px);
    font-weight: 600;

    &.desktop, &.tablet {
      top: px-to-rem(530px);
      left: px-to-rem(160px);
      width: px-to-rem(202px);
    }

    &.mobile {
      top: px-to-rem(304px);
      left: 0;
      width: 100%;
      text-align: center;
    }
  }

  &.arrows {
    //pointer-events: auto;
    color: $beige;
    width: fit-content !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;


    &.desktop, &.tablet {
      bottom: px-to-rem(180px);
      left: px-to-rem(456px);
      width: px-to-rem(31px);
    }

    &.mobile {
      bottom: px-to-rem(72px);
      left: 0;
      width: 100%;
      text-align: center;
    }

    svg {
      //pointer-events: auto;

      &:hover {
        cursor: pointer;
      }
    }
  }
}