@import 'src/styles/shared';

.zoom-in-out {
  position: fixed;
  z-index: 999;

  .zoom-in-out-inactive {
    opacity: 0.3;
    pointer-events: none;
  }

  .zoom-in-out-active {
    opacity: 1;
    pointer-events: auto;
  }

  & svg {
    width: 100%;
    height: 100%;
  }

  &.desktop,
  &.tablet {
    .zoom-in {
      width: px-to-rem(55px);
      height: px-to-rem(55px);
      right: px-to-rem(210px);
      bottom: px-to-rem(179px);
    }
    .zoom-out {
      width: px-to-rem(35px);
      height: px-to-rem(35px);
      right: px-to-rem(259px);
      bottom: px-to-rem(156px);
    }
  }

  &.mobile {
    .zoom-in {
      width: px-to-rem(55px);
      height: px-to-rem(55px);
      right: px-to-rem(51px);
      bottom: px-to-rem(84px);
    }
    .zoom-out {
      width: px-to-rem(35px);
      height: px-to-rem(35px);
      right: px-to-rem(100px);
      bottom: px-to-rem(61px);
    }
  }

  .zoom-in:hover,
  .zoom-out:hover {
      cursor: pointer;
  }
}