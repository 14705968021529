@import './variables';
@import './functions';

@mixin header-background($account-type) {
  $color: get-main-color($account-type);
  background: transparent linear-gradient(270deg, rgba($color, 0), rgba($color, 1));
}

@mixin solid-background($account-type) {
  $color: get-main-color($account-type);
  background-color: $color;
}

@mixin debug-child-div-random-background($number, $alpha: 0.33) {
  @for $i from 1 to $number {
    div:nth-child(#{$i}) {
      @include debug-random-background($alpha);
      //background: hsla(random(360), 100%, 50%, $alpha);
    }
  }
}

@mixin debug-random-background($alpha: 0.33) {
  background: hsla(random(360), 100%, 50%, $alpha);
}

@mixin fill($selectors, $color) {
  #{$selectors} {
    fill: $color;
  }
}

@mixin font-display() {
  font-family: 'Lato', sans-serif;
}

@mixin font-sans-serif() {
  font-family: 'Lato', sans-serif;
}

@mixin font-serif() {
  font-family: 'Libre Baskerville', serif;
}

/**
 Will set background-image for this element using the following scheme:
 - {$url}_default.{$ext}
 - {$url}_hover.{$ext}
 - {$url}_active.{$ext}

 @url Set url to base image name without _default.{$ext} / _hover.{$ext} / _active.{$ext}
 */
@mixin background-hover($url, $ext: "png", $position: center, $backgroundSize: contain) {
  background-image: url($url + "_default." + $ext) !important;
  background-repeat: no-repeat !important;
  background-position: $position !important;
  background-size: $backgroundSize !important;

  &:hover {
    cursor: pointer;
    background-image: url($url + "_hover." + $ext) !important;
  }

  &:active {
    background-image: url($url + "_active." + $ext) !important;
  }
}