@import 'src/styles/shared';

.main {
  //@include debug-child-div-random-background(10, 0.15); // TODO REMOVE DEBUG

  background-color: white;
  z-index: 700; // below Header anv above Canvas
  position: absolute;
  top: $header-height;
  left: 0;
  width: 100vw;
  height: calc(100vh - #{$header-height}); // sass error without calc
  //overflow: hidden;
  overflow-x: hidden;
  overflow-y: auto;
}

//h1, h2, h3, h4 {
//  font-family: 'Roboto Slab', sans-serif;
//}

h1, h2 {
  color: $blue-light;
  font-size: 22px;
  font-weight: normal;
  margin-bottom: .75rem;
  @include font-serif();
}