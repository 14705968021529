@import 'src/styles/shared';

.annotation-overlay-container {
  z-index: 1099;
  pointer-events: auto;
  position: fixed;
  bottom: 0;
  background-color: black;

  transform: translateY(100%);
  opacity: 0;
  transition: all $transition-delay-300;

  &::before, &::after {
    content: "";
    position: absolute;

    background-color: #b6001a;
    width: px-to-rem(20px);
    height: 80%;
  }
  &::before {
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
  }
  &::after {
    right: 0;
    top: 50%;

    transform: translate(100%, -50%);
  }

  &.desktop,
  &.tablet {
    left: px-to-rem(160px);
    width: px-to-rem(720px);
    height: px-to-rem(320px);
    padding: px-to-rem(22px) px-to-rem(40px);
  }

  &.mobile {
    left: 0;
    width: 100%;
    height: px-to-rem(289px);
    padding: px-to-rem(13px) px-to-rem(20px);
  }

  &.opened {
    transform: translateY(0);
    opacity: 1;
  }

  .annotation-overlay {

    .annotation-overlay-line {
      position: absolute;
      right: 0;

      &.desktop,
      &.tablet {
        bottom: px-to-rem(-31px);
      }

      &.mobile {
        bottom: 0;
      }
    }

    .annotation-overlay-close {
      position: absolute;

      &.desktop,
      &.tablet {
        top: px-to-rem(12px);
        right: px-to-rem(12px);
      }

      &.mobile {
        top: px-to-rem(11px);
        right: px-to-rem(10px);
      }
    }

    .annotation-overlay-title {
      @include font-sans-serif();
      color: white;
      font-weight: 700;
      letter-spacing: 1.04px;
      text-transform: uppercase;
      line-height: px-to-rem(50px);

      &.desktop,
      &.tablet {
        font-size: px-to-rem(26px);
      }

      &.mobile {
        font-size: px-to-rem(22px);
        line-height: px-to-rem(30px);
      }
    }

    //.annotation-overlay-icon {
    //  position: absolute;
    //
    //  &.desktop,
    //  &.tablet {
    //    top: px-to-rem(100px);
    //    left: px-to-rem(40px);
    //  }
    //
    //  &.mobile {
    //    top: px-to-rem(64px);
    //    left: px-to-rem(20px);
    //  }
    //}

    .annotation-overlay-content {
      position: absolute;
      @include font-sans-serif();
      color: white;

      &.desktop,
      &.tablet {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);
        top: px-to-rem(111px);
        //left: px-to-rem(194px);
        left: px-to-rem(40px);
        right: px-to-rem(40px);
      }

      &.mobile {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(24px);
        top: px-to-rem(80px);
        //left: px-to-rem(124px);
        left: px-to-rem(22px);
        right: px-to-rem(22px);
      }
    }

    .annotation-overlay-colors {
      position: absolute;
      @include font-sans-serif();
      color: white;
      display: flex;
      flex-direction: row;

      &.desktop,
      &.tablet {
        font-size: px-to-rem(20px);
        line-height: px-to-rem(30px);

        bottom: px-to-rem(36.5px);

        left: px-to-rem(40px);
        right: px-to-rem(40px);
      }

      &.mobile {
        font-size: px-to-rem(14px);
        line-height: px-to-rem(24px);

        bottom: px-to-rem(27px);

        left: px-to-rem(22px);
        right: px-to-rem(22px);
      }

      .annotation-overlay-color {
        background-color: red;

        &.desktop,
        &.tablet {
          width: px-to-rem(64px);
          height: px-to-rem(64px);
          margin: px-to-rem(10px);
          border-radius: px-to-rem(32px);
        }

        &.mobile {
          width: px-to-rem(40px);
          height: px-to-rem(40px);
          margin: px-to-rem(5px);
          border-radius: px-to-rem(20px);
        }

        &:hover {
          cursor: pointer;
          border: 2px solid white;
        }
      }
    }

    .annotation-overlay-next, .annotation-overlay-previous {
      &.active:hover {
        cursor: pointer;
      }
    }

    .annotation-overlay-next {
      position: absolute;
      right: px-to-rem(22px);
      width: px-to-rem(66px);
      height: px-to-rem(66px);
      pointer-events: none;
      background: center / contain no-repeat url("../../../../public/assets/img/annotation_fleche_inactive_droite.png");

      border-radius: px-to-rem(33px);

      &.active {
        pointer-events: auto;
        background: center / contain no-repeat url("../../../../public/assets/img/annotation_fleche_active_droite.png");

        box-shadow: 0.2rem 0.2rem 0.5rem 0 black;
      }

      &.desktop,
      &.tablet {
        bottom: px-to-rem(51.5px);
      }

      &.mobile {
        bottom: px-to-rem(42px);
      }
    }

    .annotation-overlay-previous {
      position: absolute;
      right: px-to-rem(81px);
      width: px-to-rem(61px);
      height: px-to-rem(61px);
      pointer-events: none;
      background: center / contain no-repeat url("../../../../public/assets/img/annotation_fleche_inactive_gauche.png");

      border-radius: px-to-rem(30.5px);

      &.active {
        pointer-events: auto;
        background: center / contain no-repeat url("../../../../public/assets/img/annotation_fleche_active_gauche.png");
      }

      &.desktop,
      &.tablet {
        bottom: px-to-rem(36.5px);
      }

      &.mobile {
        bottom: px-to-rem(27px);
      }
    }
  }
}
