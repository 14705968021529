@import 'src/styles/shared';

.product-sheet-contact-link {
  position: fixed;
  z-index: 2000;
  color: red;
  width: 66%;
  display: flex;
  height: px-to-rem(128px);
  justify-content: start;
  transition: opacity $transition-delay-300;
  pointer-events: none;

  a {
    align-self: end;
    text-decoration: none;
    color: $grey-border;
    text-transform: uppercase;
    margin-bottom: 2em;
    margin-left: 2em;
    pointer-events: auto;
    cursor: pointer;
  }

  &.visible {
    transition: opacity $transition-delay-300;
    opacity: 1;
  }

  &.desktop,
  &.tablet {
    right: 0;
  }

  &.mobile {
    bottom: 0;
    text-align: center;
    width: 100%;
    height: px-to-rem(48px);
  }
}