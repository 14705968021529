@import 'src/styles/shared';

.product-sheet-logo {
  z-index: 999;
  position: absolute;
  width: auto;
  color: $beige;

  .product-sheet-logo-png {
    width: 100%;
    height: 100%;
  }

  .product-sheet-logo-svg {
    width: 100%;
    height: 100%;

    & * {
      fill: black;
      //shape-rendering: geometricPrecision;
    }
  }

  &.desktop,
  &.tablet {
    height: px-to-rem(96px);
    top: px-to-rem(86px);
    left: px-to-rem(100px);
  }
  &.mobile {
    height: px-to-rem(48px);
    top: px-to-rem(52px);
    left: px-to-rem(26px);
  }
}