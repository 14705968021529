@import url('https://fonts.googleapis.com/css2?family=Teko:wght@600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');

@import "NunitoSans";

//  100   Thin (Hairline)
//  200   Extra Light (Ultra Light)
//  300   Light
//  400   Normal (Regular)
//  500   Medium
//  600   Semi Bold (Demi Bold)
//  700   Bold
//  800	  Extra Bold (Ultra Bold)
//  900   Black (Heavy)

*, html {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  //font-family: 'Roboto Slab', sans-serif;
  font-family: 'Lato', sans-serif; // no mixin here

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

#root {
  height: 100vh;
  --toastify-color-progress-light: magenta; // TODO CHANGE COLOR TOSTIFY
  --toastify-font-family: 'Lato', sans-serif;
}
